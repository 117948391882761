<template>
  <c-box
    width="100%"
    max-width="1270px"
    :margin="['0 auto', '16px auto']"
    height="auto"
    background-color="#FFF"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <c-box
      margin="auto"
      width="100%"
      overflow="hidden"
    >
      <c-box
        height="1300px"
      >
        <iframe
          :src="getIframeLink"
          frameborder="0"
          style="display:block; width:100%; height: 100%;"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { ENV } from '@/constants/config'

export default {
  name: 'BlogPage',
  computed: {
    getIframeLink() {
      return `${ENV.URL_DIETELA_WORDPRESS}/blog/`
    },
  },
}
</script>
