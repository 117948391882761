var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "margin": ['0 auto', '16px auto'],
      "height": "auto",
      "background-color": "#FFF",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-box', {
    attrs: {
      "margin": "auto",
      "width": "100%",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "height": "1300px"
    }
  }, [_c('iframe', {
    staticStyle: {
      "display": "block",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.getIframeLink,
      "frameborder": "0"
    }
  })])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }